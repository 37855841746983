import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../api/api";
import "./cusdetail.css";

const CusDetail = () => {
  const navigate = useNavigate();
  const { cusid } = useParams();

  const [cusdata, setCusdata] = useState(null);
  // console.log("cusdata:".cusdata);
  useEffect(() => {
    fetch(`${apiUrl}/api/customer/${cusid}`)
      .then((res) => res.json())
      .then((resp) => {
        setCusdata(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [cusid]);

  return (
    <div>
      <div className="row ">
        <div>
          <div className="container">
            <div className="card row" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Customer Details:</h2>
              </div>

              {cusdata ? (
                <div>
                  <h2>
                    Customer's Name: <b style={{textTransform:"uppercase"}}>{cusdata.name}</b>
                  </h2>
                  <h5>Customer's ID: {cusdata._id}</h5>

                  <h3>Contact Details</h3>
                  <table style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <td>{cusdata.email}</td>
                      </tr>
                      <tr>
                        <th>Mobile No.</th>
                        <td>{cusdata.phone_no}</td>
                      </tr>
                     </tbody>
                  </table>
<br></br>
<h3>All Bookings:</h3>
                  {cusdata.myOrders && cusdata.myOrders.length > 0 ? (
                    <>
                      <table className="tableBox" style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
                        <thead>
                          <tr>
                            <th className="boxborder">#Order</th>
                            <th className="boxborder">Customer Name</th>
                            <th className="boxborder">Product Names</th>
                            <th className="boxborder">Category</th>
                            <th className="boxborder">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cusdata.myOrders.map((order, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{order.customer_name}</td>
                              <td>
                                {order.product_name[0]?.product_name || "N/A"}
                              </td>
                              <td>{order.category}</td>
                              <td>{order.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="card-title">
                        <h2>Last Order Details:</h2>
                        <table style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
                          <tbody>
                            <tr>
                              <th>Order Customer Name</th>
                              <th>Order Mobile No.</th>
                              <th>Category</th>
                              <th>City</th>
                              <th>Description</th>
                              <th>Final Total</th>
                              <th>Location</th>
                              <th>Make and Model</th>
                              
                            </tr>
                            <tr>
                            <td>{cusdata.myOrders[0].customer_name}</td>
                              <td>{cusdata.myOrders[0].cusNumber}</td>
                              <td>{cusdata.myOrders[0].category}</td>
                              <td>{cusdata.myOrders[0].city}</td>
                              <td>{cusdata.myOrders[0].discription}</td>
                              <td>{cusdata.myOrders[0].final_total}</td>
                              <td>{cusdata.myOrders[0].location}</td>
                              <td>{cusdata.myOrders[0].make_model}</td>
                            </tr>
                           <br></br>
                            <tr>
                              <th>Spl. Note</th>
                              <th>Pincode</th>
                              <th>Registration Number</th>
                              <th>Servicing Date</th>
                              <th>Servicing Time</th>
                              <th>State</th>
                              <th>Status</th>
                              <th>Product Name</th>
                              
                            </tr>
                            <tr>
                            <td>{cusdata.myOrders[0].note}</td>
                              <td>{cusdata.myOrders[0].pincode}</td>
                              <td>{cusdata.myOrders[0].reg_number}</td>
                              <td>{cusdata.myOrders[0].servicing_date}</td>
                              <td>{cusdata.myOrders[0].servicing_time}</td>
                              <td>{cusdata.myOrders[0].state}</td>
                              <td>{cusdata.myOrders[0].status}</td>
                              <td>
                                {cusdata.myOrders[0].product_name
                                  ? cusdata.myOrders[0].product_name
                                      .map((product) => product.name)
                                      .join(", ")
                                  : "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                       
                      </div>
                    </>
                  ) : (
                    <p>No orders found.</p>
                  )}

                  

                  <h3> Registered Vehicles</h3>
                  {cusdata.vehicles && cusdata.vehicles.length > 0 ? (
                    <table style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
                      <thead style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#FEC324" , textTransform: "uppercase" , fontWeight:"bold"}}>
                        <tr>
                          <th>Type</th>
                          <th>Vehicle Reg No.</th>
                          <th>Make and Model</th>
                          <th>Variant</th>
                          <th>Fuel Type</th>
                          <th>Bike CC</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {cusdata.vehicles.map((vehicle, index) => (
                          <tr key={index}>
                            <td>{vehicle.vehicle_type}</td>
                            <td>{vehicle.reg_number}</td>
                            <td>{vehicle.make_model}</td>
                            <td>{vehicle.variant}</td>
                            <td>{vehicle.fuel_type}</td>
                            <td>
                              {vehicle.vehicle_type === "Bike"
                                ? vehicle.bike_cc
                                : "N/A"}
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No vehicles found.</p>
                  )}
<br></br>
                  <h3>Abandoned Cart</h3>
                  {cusdata.mycart && cusdata.mycart.length > 0 ? (
                    <table style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
                      <thead>
                        <tr>{/* Define the headers for the cart items */}</tr>
                      </thead>
                      <tbody>
                        {cusdata.mycart.map((item, index) => (
                          <tr key={index}>{/* Render cart item details */}</tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Cart is empty.</p>
                  )}

                  <button
                    className="btn btn-danger"
                    onClick={() => navigate(-1)}
                  >
                    Go back
                  </button>
                </div>
              ) : (
                <p>Loading customer details...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CusDetail;
