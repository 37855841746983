import React, { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const BackgroundVideo = ({ src, muted }) => {
  const videoRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const { inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    setIsVisible(inView);
  }, [inView]);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      muted={muted}
      preload="auto"
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        objectFit: "cover",
        zIndex: -1,
      }}
    >
      <source src={src} type="video/mp4" />
      {/* Add more source elements for other video formats */}
    </video>
  );
};

export default BackgroundVideo;
