import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { ordersData } from "../../api/api.js";
import OrdPickup from "./OrdPickup.jsx";
import { apiUrl } from "../../api/api.js";

const OrdEdit = () => {
  const { ordid } = useParams();
  // console.log(ordid);

  useEffect(() => {
    fetch(`${apiUrl}/${ordid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setOrderData(resp);

        idchange(resp.id);
        setStatus(resp.status);
        setEmail(resp.billing.email);
        phonechange(resp.billing.phone);
        setFirstName(resp.billing.first_name);
        setLastName(resp.billing.last_name);
        setAddress(resp.billing.address_1); 
        setNeeds_payment(resp.needs_payment);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [ordid]);
  const [orderData, setOrderData] = useState();
  const [id, idchange] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [phone, phonechange] = useState();

  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [address_1, setAddress] = useState();

  const [needs_payment, setNeeds_payment] = useState(); 
   const navigate = useNavigate();

  const handlesubmit = (e) => {
    e.preventDefault();

    const orderdata = {
      id,
      status,
      billing: { phone, first_name, last_name, address_1 },
      
      needs_payment,
    };

    fetch(`${apiUrl}/${ordid}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(orderdata),
    })
      .then((res) => {
        alert("Saved successfully.");
        navigate("/Orders");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
 

  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Order Edit</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        value={id}
                        disabled="disabled"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={email}
                        disabled="disabled"
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Status</label>
                      <input
                        required
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        value={phone}
                        onChange={(e) => phonechange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>{" "}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>{" "}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        value={address_1}
                        onChange={(e) => setAddress(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                   
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Select Payment:</label>
                      <input
                        required
                        value={needs_payment}
                        onChange={(e) => setNeeds_payment(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                    <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      
      <OrdPickup />
      <div>
        {orderData &&
          orderData.line_items &&
          orderData.line_items.map((item) => (
            <div key={item.id}>
              <p>{item.name}</p>
              <p>{item.subtotal}</p>
              <table>
                <tr>
                  <th>Cost</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <td>{item.price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total}</td>
                </tr>
              </table>
              <br />
              <span>Items Subtotal:</span> <span>${item.subtotal}</span>
              <br />
              <span>Order Total:</span> <span>${item.total}</span>
              <hr />
              <span>Paid:</span> <span>${item.total}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrdEdit;
