import React, { useEffect, useState } from "react";
// import MainScreen from "../../components/MainScreen";
import { Button, Card, Form } from "react-bootstrap";

function SubUserRegister() {
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [area, setarea] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const resetHandler = () => {
    setTitle("");
    setPosition("");
    setfirst_name("");
    setlast_name("");
    setarea("");
    setphone("");
    setemail("");
    setpassword("");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const postData = {
      title,
      position,
      first_name,
      last_name,
      area,
      phone,
      email,
      password,
    };

    const data = JSON.parse(localStorage.getItem("userInfo")) || {};
    const token = data.token;

    // console.log("token:", token);
    fetch("https://gmi-server-1.onrender.com/api/subusers/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Data was successfully posted to the API");
        } else {
          console.log("Failed to post data to the API");
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });

    resetHandler();
  };

  useEffect(() => {}, []);

  return (
    // <MainScreen title="Create User (Garage/pick-up) ">
    <Card>
      <Card.Header>
        <h2>Create User: (Garage/pick-up)</h2>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={submitHandler}>
          {/* {error && <ErrorMessage variant="danger">{error}</ErrorMessage>} */}
          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="title"
              value={title}
              placeholder="Enter the title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          {/* /////,,,,,,,,,,,,,,,  select options >>>   ,,,,,,,,,,,,,,,,,,,,,, */}

          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select an Position:</Form.Label>
            <select
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              {/* <select value={position} onChange={SelectPosition}></select> */}
              <option value="select">Select : </option>
              <option value="garage">Service Provider</option>
              <option value="garage">Garage</option>
              <option value="pickup">Pick up</option>
            </select>
          </Form.Group>

          {/* /////,,,,,,,,,,,,,,,,  <<<< select options   ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */}
          <Form.Group controlId="content">
            <Form.Label>first_name</Form.Label>
            <Form.Control
              type="content"
              value={first_name}
              placeholder="Enter the first_name"
              onChange={(e) => setfirst_name(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label>last_name</Form.Label>
            <Form.Control
              type="content"
              value={last_name}
              placeholder="Enter the last_name"
              // rows={4}
              onChange={(e) => setlast_name(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label>area</Form.Label>
            <Form.Control
              type="content"
              value={area}
              placeholder="Enter the area"
              // rows={4}
              onChange={(e) => setarea(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label>phone</Form.Label>
            <Form.Control
              type="content"
              value={phone}
              placeholder="Enter the phone"
              // rows={4}
              onChange={(e) => setphone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label>email</Form.Label>
            <Form.Control
              type="content"
              value={email}
              placeholder="Enter the email"
              // rows={4}
              onChange={(e) => setemail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label>password</Form.Label>
            <Form.Control
              type="content"
              value={password}
              placeholder="Enter the password"
              // rows={4}
              onChange={(e) => setpassword(e.target.value)}
            />
          </Form.Group>
          {/* {loading && <Loading size={50} />} */}
          <Button type="submit" variant="primary">
            Create SubUser
          </Button>
          <Button className="mx-2" onClick={resetHandler} variant="danger">
            Reset Feilds
          </Button>
        </Form>
      </Card.Body>

      <Card.Footer className="text-muted">
        Creating on - {new Date().toLocaleDateString()}
      </Card.Footer>
    </Card>
    // </MainScreen>
  );
}

export default SubUserRegister;
