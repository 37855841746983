import React from "react";
import withPasswordProtection from "./withPasswordProtection";
import ProtectedPage from "./ProtectedPage";

const password = "Pass@123";

const ProtectedPageWithPasswordProtection = withPasswordProtection(
  ProtectedPage,
  password
);

const PrivatePage = () => {
  return <ProtectedPageWithPasswordProtection />;
};

export default PrivatePage;
