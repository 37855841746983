import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../api/api"; 

const OrdCreate = () => {
  const navigate = useNavigate(); 

  const [currency, setCurrency] = useState("");
  const [quantity, setQuantity] = useState("");
  const [customer_id, setCustomer_id] = useState(""); 
  const [product_id, setProduct_id] = useState(""); 

  const handlesubmit = (e) => {
    e.preventDefault();
     
    const orderdata = {
      currency,
      customer_id,
      set_paid: true,
      line_items: [{ product_id, quantity }],
    };

    fetch(`${apiUrl}/add`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(orderdata),
    })
      .then((res) => {
        alert("Saved successfully.");
        navigate("/orders");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Order Create</h2>
              </div>
              <div className="card-body">
                <div className="row">
                 

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Currency</label>
                      <input
                        required
                        value={currency}
                        // onMouseDown={(e) => valchange(true)}
                        onChange={(e) => setCurrency(e.target.value)}
                        className="form-control"
                      ></input>
                   
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>customer_id</label>
                      <input
                        required
                        value={customer_id}
                         onChange={(e) => setCustomer_id(e.target.value)}
                        className="form-control"
                      ></input>
                      
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Quantity</label>
                      <input
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>product_id</label>
                      <input
                        value={product_id}
                        onChange={(e) => setProduct_id(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save
                      </button>

                      <button
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrdCreate;
