import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../api/api";
import { FaEdit } from "react-icons/fa";
import { MdDetails } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";

const ServiceProvider = () => {
  const [data, setData] = useState([]);
  // console.log("data:", data);
  const garageData = data.filter(
    (item) => item.position === "garage" || "self"
  );
  // console.log("garageData:", garageData);
  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/garage/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/garage/edit/${_id}`);
  const AllotOrder = (_id) => navigate(`/garage/allot/${_id}`);

  const AllotedOrders = (_id) => navigate(`/garage/allotedorders/${_id}`);

  const Removefunction = (_id) => {
    if (window.confirm("Do you want to remove ?")) {
      fetch(`https://gmi-server-1.onrender.com/api/subusers/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          alert("Removed successfully.");
          window.location.reload();
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    fetch(`${apiUrl}/api/subusers`)
      .then((res) => res.json())
      .then((resp) => setData(resp))
      .catch((err) => console.log(err.message));
  }, []);

  return (
    <div>
      <div className="container">
        <div className="card" style={{ width: "102%" }}>
          <div className="card-title">
            <h2>Service Provider's List</h2>
          </div>
          <div className="card-body">
            <div className="divbtn">
              <button
                onClick={() => navigate("/subuser/create")}
                className="btn btn-success"
              >
                {"Add New (+)"}
              </button>

              <button
                onClick={() => navigate("/alljobcarts")}
                className="btn btn-primary"
              >
                {"All Job Carts"}
              </button>
            </div>
            <table className="">
              <thead className="bg-dark text-white">
                <tr style={{ textAlign: "center" }}>
                  <td>title</td>
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Status</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody style={{ border: "1px solid black" }}>
                {data.map((item) => (
                  <tr key={item._id}>
                    <td style={{ border: "1px solid black", padding: "2px" }}>
                      {item.title}
                    </td>
                    <td style={{ border: "1px solid black", padding: "2px" }}>
                      {item.first_name} {item.last_name}
                    </td>
                    <td style={{ border: "1px solid black", padding: "2px" }}>
                      {item.phone}
                    </td>
                    <td
                      style={{
                        // display: "flex",
                        // justifyContent: "space-around",
                        alignItems: "center",
                        textAlign: "center",
                        // margin: "auto",
                        // marginTop: "20%",
                        // width: "20px",
                        // height: "20px",
                        border: "1px solid black",
                      }}
                    >
                      {item.status && item.status === true ? (
                        <i
                          className="fa fa-circle"
                          style={{ color: "green" }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      )}
                    </td>

                    <td style={{ margin: "1px" }}>
                      <button
                        onClick={() => LoadEdit(item._id)}
                        className="btn btn-success"
                        style={{ margin: "1px" }}
                      >
                        <FaEdit />
                      </button>
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => Removefunction(item._id)}
                        className="btn btn-danger"
                      >
                        <RiDeleteBinLine />
                      </button>
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary"
                      >
                        <AiOutlineOrderedList />
                      </button>
                      <button
                        style={{ margin: "1px" }}
                        onClick={() => AllotOrder(item._id)}
                        className="btn btn-warning"
                      >
                        <MdDetails />
                        Allot Ord
                      </button>

                      <button
                        style={{
                          margin: "1px",
                          backgroundColor: "rgb(245, 7, 170",
                          color: "white",
                        }}
                        onClick={() => AllotedOrders(item._id)}
                        className="btn"
                      >
                        <BsCheckCircle />
                        Alloted Ords
                      </button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceProvider;
