import React from "react";
import BackgroundVideo from "./BackgroundVideo";
import video1 from "./videos/video1.mp4";

const Setting = () => {
  const handleScroll = () => {
  };

  return (
    <div
      onScroll={handleScroll}
      style={{ height: "1000px", overflowY: "scroll" }}
    >
     
      <BackgroundVideo src={video1} muted />
    </div>
  );
};

export default Setting;
