import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { useNavigate } from "react-router-dom";

const ProtectedPage = () => {
  const navigate = useNavigate();
  const [maintenanceDateTime, setMaintenanceDateTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("12:00");

  const setMaintenanceTimeout = async (timeoutDateTime) => {
    try {
      const response = await axios.post(
        "https://gmi-server-1.onrender.com/maintenance/maintenancetimeout",
        {
          maintenanceTimeout: timeoutDateTime.toISOString(), // Convert to ISO 8601 format
        }
      );
      // console.log("Maintenance timeout set successfully:", response.data);
    } catch (error) {
      console.error("Error setting maintenance timeout:", error.message);
    }
  };

  const handleSetMaintenance = () => {
    const selectedDateTime = new Date(maintenanceDateTime);
    const [hours, minutes] = selectedTime.split(":");
    selectedDateTime.setHours(parseInt(hours, 10));
    selectedDateTime.setMinutes(parseInt(minutes, 10));
    setMaintenanceTimeout(selectedDateTime);
     navigate(-1); 
  };

  return (
    <div>
      <h2>Setting Maintenance Timeout</h2>
      <div>
        <label>Date: </label>
        <DatePicker
          selected={maintenanceDateTime}
          onChange={(date) => setMaintenanceDateTime(date)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div>
        <label>Time: </label>
        <TimePicker
          value={selectedTime}
          onChange={(time) => setSelectedTime(time)}
          format="HH:mm" // Ensure the format is set to 'HH:mm'
        />
      </div>
      <button onClick={handleSetMaintenance}>Set Maintenance Timeout</button>
    </div>
  );
};

export default ProtectedPage;
