import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const JobcartDetails = () => {
  const navigate = useNavigate();
  const { jobcid } = useParams();

  const [jobcdata, setjobcdata] = useState([]);
  // console.log("jobcdata:", jobcdata);
  useEffect(() => {
    fetch(`https://gmi-server-1.onrender.com/api/jobcart/jobcart/${jobcid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setjobcdata(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [jobcid]);
  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="container">
            <div className="card row" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Customer Details:</h2>
              </div>
              <div className="card-body"></div>

              {jobcdata &&
                jobcdata.map((item) => (
                  <div>
                    <h2>
                      _id : <b>{item._id}</b>
                    </h2>

                    <h5>name : {item.name}</h5>
                    <h5>first_name : {item.first_name}</h5>

                    <button
                      className="btn btn-danger"
                      onClick={() => navigate(-1)}
                    >
                      Go back
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobcartDetails;
