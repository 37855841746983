import React, { useState, useEffect } from "react";
import axios from "axios";
 
const Contactus = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  // Fetch contacts from the server
  useEffect(() => {
    getContactus();
  }, []);
 
  const getContactus = () => {
    axios
      .get("https://gmi-server-1.onrender.com/contactus")
      .then((response) => {
        setContacts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
 
  // Function to delete a contact
  const deleteContact = (_id) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this form details?"
    );
 
    if (confirmation) {
      axios
        .delete(`https://gmi-server-1.onrender.com/contactus/delete/${_id}`)
        .then(() => {
          getContactus();
          alert("Contact deleted successfully");
          setContacts(contacts.filter((contact) => contact._id !== _id));
        })
        .catch((error) => {
          setError(error);
        });
    }
  };
 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading contacts: {error.message}</p>;
 
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Contact Us</h1>
      <ul style={styles.list}>
        {contacts.map((contact) => (
          <li key={contact._id} style={styles.listItem}>
            <p style={styles.info}>
              <strong>Name:</strong> {contact.name}
            </p>
            <p style={styles.info}>
              <strong>Email:</strong> {contact.email}
            </p>
            <p style={styles.info}>
              <strong>Mobile No:</strong> {contact.mobile_no}
            </p>
            <p style={styles.info}>
              <strong>Address:</strong> {contact.address}
            </p>
            <button
              onClick={() => deleteContact(contact._id)}
              style={styles.button}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
 
const styles = {
  container: {
    width: "500px",
    margin: "0 auto",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    background: "#f9f9f9",
    margin: "10px 0",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  },
  info: {
    margin: "5px 0",
    color: "#555",
  },
  button: {
    backgroundColor: "#d9534f",
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "3px",
    fontSize: "16px",
  },
};
 
export default Contactus;