import React, { useState } from "react";
import PasswordForm from "./PasswordForm";

const withPasswordProtection = (Component, password) => {
  const ProtectedComponent = () => {
    const [hasEnteredPassword, setHasEnteredPassword] = useState(false);

    const handlePasswordSubmit = (enteredPassword) => {
      if (enteredPassword === password) {
        setHasEnteredPassword(true);
      }
    };

    if (!hasEnteredPassword) {
      return <PasswordForm onPasswordSubmit={handlePasswordSubmit} />;
    }

    return <Component />;
  };

  return ProtectedComponent;
};

export default withPasswordProtection;
