import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const GaragePage = () => {
  const [data, setData] = useState([]);
  // console.log("data:", data);
  const garageData = data.filter((item) => item.position === "garage");
  console.log("garageData:", garageData);
  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/garage/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/garage/edit/${_id}`);
  const AllotOrder = (_id) => navigate(`/garage/allot/${_id}`);

  const Removefunction = (_id) => {
    if (window.confirm("Do you want to remove ?")) {
      fetch(`https://gmi-server-1.onrender.com/api/subusers/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          alert("Removed successfully.");
          window.location.reload();
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userInfo")) || {};
    const token = data.token;
    fetch("https://gmi-server-1.onrender.com/api/subusers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
 
  const handleStatus = (item) => {
    // console.log("StatusTry:", item);
  };
 
  return (
    <div>
      <div className="container">
        <div className="card">
          <div className="card-title">
            <h2>Garage Listing</h2>
          </div>
          <div className="card-body">
            <div className="divbtn">
              <button
                onClick={() => navigate("/subuser/create")}
                className="btn btn-success"
              >
                {"Add New (+)"}
              </button>
            </div>
            <table className="">
              <thead className="bg-dark text-white">
                {/* <tr> */}
                <td>title</td>
                <td>first_name</td>
                <td>last_name</td>
                <td>Phone</td>
                <td>Orders</td>
                <td>Action</td>
                {/* </tr> */}
              </thead>
              <tbody>
                {garageData.map((item) => (
                  <tr key={item._id}>
                    <td claspan="">{item.title}</td>
                    <td claspan="">{item.first_name}</td>
                    <td claspan="">{item.last_name}</td>
                    <td claspan="">{item.phone}</td>
                    {/* <td claspan="">
                      {(item.additional_data &&
                        item.additional_data[0].order_name === "pending") ||
                      "processing"
                        ? "Order Alloted"
                        : "Not allot yet"}
                    </td> */}
                    <td
                      claspan=""
                      onClick={() => {
                        handleStatus(item.additional_data[0]);
                      }}
                    >
                      status
                    </td>
                    {/* <td claspan="">{orderStatus}</td> */}
                    <td claspan="">
                      <button
                        onClick={() => LoadEdit(item._id)}
                        className="btn btn-success"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => Removefunction(item._id)}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => AllotOrder(item._id)}
                        className="btn btn-warning"
                      >
                        Allot Order
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GaragePage;
