import React, { useEffect, useState } from "react";
import axios from "axios";

function ImagesUpload() {
  const [image, setImage] = useState(null);
  const [allImage, setAllImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getImage();
  }, []);

  const submitImage = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("image", image);

    try {
      const result = await axios.post(
        "https://gmi-server-1.onrender.com/images/upload-image",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Image uploaded successfully!");
      getImage();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async (imageId) => {
    // Ask for confirmation
    const confirmation = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmation) {
      try {
        const result = await axios.delete(
          `https://gmi-server-1.onrender.com/images/${imageId}`
        );
        alert("Image deleted successfully!");
        getImage();
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };

  const onInputChange = (e) => {
    setImage(e.target.files[0]);
  };

  const getImage = async () => {
    try {
      const result = await axios.get(
        "https://gmi-server-1.onrender.com/images/get-image"
      );
      setAllImage(result.data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <div>
      <h2>Upload Images:</h2>
      <form onSubmit={submitImage}>
        <input type="file" accept="image/*" onChange={onInputChange}></input>
        <button type="submit">Submit</button>
      </form>
      {loading && <p>Loading...</p>}
      {allImage &&
        allImage.map((data) => (
          <div key={data._id} style={{ display: "flex", alignItems: "center" }}>
            <img
              src={`https://gmi-server-1.onrender.com/uploadImages/${data.image}`}
              alt={data.image}
              height={100}
              width={100}
              style={{ flex: "0 0 auto", marginRight: "10px" }}
            />
            <h6 style={{ flex: "1" }}>{data.image}</h6>
            <button onClick={() => deleteImage(data._id)}>Delete</button>
          </div>
        ))}
    </div>
  );
}

export default ImagesUpload;
