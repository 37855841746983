import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GAllotOrder from "./GAllotOrder";

const GarEdit = () => {
  const { garid } = useParams();
  // console.log(garid);

  useEffect(() => {
    fetch(`https://gmi-server-1.onrender.com/api/subusers/${garid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        idchange(resp._id);
        settitle(resp.title);
        setposition(resp.position);
        setfirst_name(resp.first_name);
        setlast_name(resp.last_name);
        setarea(resp.area);
        setphone(resp.phone);
        setemail(resp.email);
        setpassword(resp.password);
        setorder_name(resp.additional_data[0].order_name);

        // setorder(resp.order);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [garid]);

  const [_id, idchange] = useState("");

  const [title, settitle] = useState("");
  const [position, setposition] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [area, setarea] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [order_name, setorder_name] = useState("");

  const [name, setPro_name] = useState("");

  const [order, setorder] = useState("");
  // console.log("name data:", name);
  const navigate = useNavigate();

  const handlesubmit = (event) => {
    event.preventDefault();

 
    const garagedata = {
      _id,
      title,
      position,
      first_name,
      last_name,
      area,
      phone,
      email,
      password,
      order_name,
      // name,
      order,
    };

    fetch(`https://gmi-server-1.onrender.com/api/subusers/${garid}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(garagedata),
    })
      .then((res) => {
        alert("Update successfully.");
        // navigate("/garages");
        navigate("/serviceprovider");
      })
      .catch((err) => {
        console.log(err.message);
      });

    
  };

  //toggle dropdown ///////

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };
 
  const [dataFromChild, setDataFromChild] = useState("");

  const handleDataFromChild = (data) => {
    // console.log("datata:", data);
    setDataFromChild(data);
    setorder_name(data[1]);
    setPro_name(data[1]);
  };

  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Garage User Edit</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        value={_id}
                        disabled="disabled"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>title</label>
                      <input
                        required
                        value={title}
                        onChange={(e) => settitle(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>position</label>
                      <input
                        value={position}
                        onChange={(e) => setposition(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>first_name</label>
                      <input
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>last_name</label>
                      <input
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>area</label>
                      <input
                        value={area}
                        onChange={(e) => setarea(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>phone</label>
                      <input
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>email</label>
                      <input
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>password</label>
                      <input
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  
                  <hr />

                   
                  <hr />

                  <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save
                      </button>
                      <div
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GarEdit;
