// PasswordForm.js
import React, { useState } from "react";
import './pass.css'

const PasswordForm = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onPasswordSubmit(password);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <label>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          style={{
            // padding: "5%",
            border: "none",
            backgroundColor: "transparent",
            cursor: "none",
          }}
        />
      </label>
      <button
        style={{
          padding: "5%",
          border: "none",
          backgroundColor: "transparent",
          cursor: "none",
        }}
        type="submit"
      ></button>
    </form>
  );
};

export default PasswordForm;
