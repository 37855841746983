import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../api/api";

const CusEdit = () => {
  const { cusid } = useParams();
  // console.log("cusid:", cusid);

  useEffect(() => {
    fetch(`${apiUrl}/api/customer/${cusid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        idchange(resp.id);
        namechange(resp.name);
        emailchange(resp.email);
        phone_nochange(resp.phone_no);
        addresschange(resp.address);
        
     
        activechange(resp.isactive);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [cusid]);

  const [id, idchange] = useState("");
  const [name, namechange] = useState("");
  const [email, emailchange] = useState("");
  const [phone_no, phone_nochange] = useState();
  const [address, addresschange] = useState("");
  const [active, activechange] = useState(true);
  const [validation, valchange] = useState(false);
  // console.log("data:", id, name, email);
  const navigate = useNavigate();

  const handlesubmit = (e) => {
    e.preventDefault();
    const empdata = { id, name, email, phone_no, address};

  
    fetch(`${apiUrl}/api/customer/${cusid}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        alert("Saved successfully.");
        navigate("/customers");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <div>
      <div className="row">
        <div className="offset-lg-12">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" , background:"#fafae8"}}>
              <div className="card-title">
                <h2>Edit a Customer</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        value={id}
                        disabled="disabled"
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        required
                        value={name}
                        onMouseDown={(e) => valchange(true)}
                        onChange={(e) => namechange(e.target.value)}
                        className="form-control"
                      ></input>
                      {name.length === 0 && validation && (
                        <span className="text-danger">Enter the name</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email Id</label>
                      <input
                        value={email}
                        onChange={(e) => emailchange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Mobile No.</label>
                      <input
                        value={phone_no}
                        onChange={(e) => phone_nochange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        value={address}
                        onChange={(e) => addresschange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                 
                  <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save & Update
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CusEdit;
