 
import axios from "axios";

const API_URL = "https://gmi-server-1.onrender.com/api/products";

const createProduct = async (productData) => {
  const response = await axios.post(`${API_URL}/newproduct`, productData);
  return response.data;
};

const getAllProducts = async () => {
  const response = await axios.get(`${API_URL}/allproducts`);
  return response.data;
};

const getProductById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

const updateProduct = async (id, productData) => {
  const response = await axios.put(
    `${API_URL}/update-product/${id}`,
    productData
  );
  return response.data;
};

const deleteProduct = async (id) => {
  const response = await axios.delete(`${API_URL}/delete-product/${id}`);
  return response.data;
};

export default {
  createProduct,
  getAllProducts,
  getProductById,
  updateProduct,
  deleteProduct,
};
