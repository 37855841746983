import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SubmitOrderDetailForm from "./SubmitOrderDetailForm";
import { apiUrl } from "../../api/api";

const ApplyOrderList = () => {
  const [data, setData] = useState("");
  // console.log("data:",data)
  const navigate = useNavigate();

  const JobCartPage = (item) => {
    setData([
      item._id,
      item.customerInfo?.[0]?.customer_name,
      item.customerInfo?.[0]?.cusNumber,
      item.customerInfo?.[0]?.final_total,
      item.customerInfo?.[0]?.location,
      item.serviceProInfo?.[0]?.id,
      item.customerInfo?.[0]?.servicing_date,
      item.customerInfo?.[0]?.reg_number,
    ]);
  };

  const [products, setProducts] = useState([]);
  const BikeData = products.filter((item) => item.status === "completed");

  useEffect(() => {
    fetch(`${apiUrl}/api/completedorder`)
      .then((res) => res.json())
      .then((resp) => setProducts(resp))
      .catch((err) => console.log(err.message));
  }, []);

  return (
    <div>
      <SubmitOrderDetailForm data={data} />
      <br />
      <hr />
      <h4>Completed Orders list</h4>
      <table className="">
        <thead className="bg-dark text-white">
          <tr>
            <td className="border" colSpan="1">
              #_id
            </td>
            <td className="border" colSpan="1">
              customer_name
            </td>
            <td className="border" colSpan="1">
              cusNumber
            </td>
            <td className="border" colSpan="1">
              final_total
            </td>
            <td className="border" colSpan="1">
              location
            </td>
            <td className="border" colSpan="1">
              reg_number
            </td>
            <td className="border" colSpan="1">
              servicing_date
            </td>
            <td className="border" colSpan="1">
              service pro ID
            </td>
            <td className="border" colSpan="1">
              Action
            </td>
          </tr>
        </thead>
        <tbody>
          {products.map((item) => (
            <tr key={item._id}>
              <td className="border1" colSpan="">
                #_{item._id.slice(-4)}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.customer_name || "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.cusNumber?.toString().slice(-3) ||
                  "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.final_total || "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.location || "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.reg_number || "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.customerInfo?.[0]?.servicing_date || "N/A"}
              </td>
              <td className="border1" colSpan="">
                {item.serviceProInfo?.[0]?.id || "N/A"}
              </td>
              <td className="border">
                <button
                  onClick={() => JobCartPage(item)}
                  className="btn btn-success"
                >
                  Apply
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApplyOrderList;
