import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const JobCart = (props) => {
  const navigate = useNavigate();
  // console.log("productsData in JobCart:", props.data);

  const [formData, setFormData] = useState({
    name: "",
    order_id: "",
    first_name: "",
    email: "",
    location: "",
  });

  useEffect(() => {
    setFormData({
      name: props.data[1],
      order_id: props.data[0],
      first_name: "",
      email: "",
      location: "",
    });
  }, [props.data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://gmi-server-1.onrender.com/api/jobcart/addjobcart",
        formData
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    handleReset();
  };

  const handleChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleReset = () => {
    setFormData({
      name: "",
      order_id: "",
      first_name: "",
      email: "",
      location: "",
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Name : </Form.Label>

          <Form.Control
            type="text"
            value={formData.name}
            placeholder="Select the Name"
            onChange={(event) => handleChange(event, "name")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Order_id : </Form.Label>
          <Form.Control
            type="text"
            value={formData.order_id}
            placeholder="Select the order_id"
            onChange={(event) => handleChange(event, "order_id")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label> First Name : </Form.Label>

          <Form.Control
            type="text"
            value={formData.first_name}
            placeholder="Select the Name"
            onChange={(event) => handleChange(event, "first_name")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email : </Form.Label>
          <Form.Control
            type="text"
            value={formData.email}
            placeholder="Enter the email"
            onChange={(event) => handleChange(event, "email")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Location : </Form.Label>
          <Form.Control
            type="text"
            value={formData.location}
            placeholder="Enter the location"
            onChange={(event) => handleChange(event, "location")}
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          Submit Job Cart
        </Button>
        <Button className="mx-2" onClick={handleReset} variant="warning">
          Reset Feilds
        </Button>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="danger"
        >
          Go Back
        </Button>
      </Form>
    </>
  );
};
export default JobCart;
