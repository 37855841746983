// src/App.js
import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import Dashboard from "./pages/DashboardPages/Dashboard";
import Setting from "./pages/DashboardPages/Setting";
import Order from "./pages/OrderPages/Order";
import OrdCreate from "./pages/OrderPages/OrdCreate";
import OrdDetail from "./pages/OrderPages/OrdDetail";
import OrdEdit from "./pages/OrderPages/OrdEdit";
import Customer from "./pages/CustomerPages/Customer";
import CusCreate from "./pages/CustomerPages/CusCreate";
import CusDetail from "./pages/CustomerPages/CusDetail";
import CusEdit from "./pages/CustomerPages/CusEdit";
import Coupons from "./pages/CouponsPage/Coupons";
import Garage from "./pages/GaragePage/Garage";
import SubUserRegister from "./pages/SubUserRegister/SubUserRegister";
import GaragePage from "./pages/SubUserRegister/garage/GaragePage";
import GarDetail from "./pages/SubUserRegister/garage/GarDetail";
import GarEdit from "./pages/SubUserRegister/garage/GarEdit";
import PickupPage from "./pages/SubUserRegister/pickup/PickupPage";
import PicDetail from "./pages/SubUserRegister/pickup/PicDetail";
import PicEdit from "./pages/SubUserRegister/pickup/PicEdit";
import PAllotOrder from "./pages/SubUserRegister/pickup/PAllotOrder";
import GAllotOrder from "./pages/SubUserRegister/garage/GAllotOrder";
import ServiceProvider from "./pages/serviceProvider/ServiceProvider";
import AllotedOrdersList from "./pages/serviceProvider/AllotedOrdersList";
import JobCart from "./pages/serviceProvider/JobCart";
import AllJobCarts from "./pages/JobCart/AllJobCarts";
import Contactus from "./pages/ContactusPage/Contactus";
import JobcartDetails from "./pages/JobCart/JobcartDetails";
import AllotProvider from "./pages/OrderPages/AllotProvider";
import LoginForm from "./components/Authentication/LoginForm";
import PrivatePage from "./pages/privatePage/PrivatePage";
import Downloadinpdf from "./pages/downloadinpdf/Downloadinpdf";
import Mapvehicles from "./pages/Vehicles/MapVehicles/Mapvehicles";
import PostVehicle from "./pages/Vehicles/PostVehicle/PostVehicle";
import ImagesUpload from "./pages/ImagesUpload/ImagesUpload";
import Products from "./pages/Products/Products";
import NotificationComponent from "./components/NotificationComponent";
import { useEffect } from "react";
import AllProducts from "./pages/Products/AllProducts";

function App() {
  

  return (
    <AuthProvider>
      <Router>
        <SideBar>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/setting"
              element={
                <PrivateRoute>
                  <Setting />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<> not found</>} />
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <Customer />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/create"
              element={
                <PrivateRoute>
                  <CusCreate />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/detail/:cusid"
              element={
                <PrivateRoute>
                  <CusDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer/edit/:cusid"
              element={
                <PrivateRoute>
                  <CusEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <PrivateRoute>
                  <Order />
                </PrivateRoute>
              }
            />
            <Route
              path="/order/create"
              element={
                <PrivateRoute>
                  <OrdCreate />
                </PrivateRoute>
              }
            />
            <Route
              path="/order/detail/:ordid"
              element={
                <PrivateRoute>
                  <OrdDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/order/edit/:ordid"
              element={
                <PrivateRoute>
                  <OrdEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/order/allotaddprovider/:ordid"
              element={
                <PrivateRoute>
                  <AllotProvider />
                </PrivateRoute>
              }
            />
            <Route
              path="/serviceprovider"
              element={
                <PrivateRoute>
                  <ServiceProvider />
                </PrivateRoute>
              }
            />
            <Route
              path="/garage/allotedorders/:garid"
              element={
                <PrivateRoute>
                  <AllotedOrdersList />
                </PrivateRoute>
              }
            />
            <Route
              path="/serviceprovider/jobcart/:garid"
              element={
                <PrivateRoute>
                  <JobCart />
                </PrivateRoute>
              }
            />
            <Route
              path="/alljobcarts"
              element={
                <PrivateRoute>
                  <AllJobCarts />
                </PrivateRoute>
              }
            />
            <Route
              path="/alljobcart/detail/:jobcid"
              element={
                <PrivateRoute>
                  <JobcartDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/garages"
              element={
                <PrivateRoute>
                  <GaragePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/garage/detail/:garid"
              element={
                <PrivateRoute>
                  <GarDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/garage/edit/:garid"
              element={
                <PrivateRoute>
                  <GarEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/garage/allot/:garid"
              element={
                <PrivateRoute>
                  <GAllotOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="/pickups"
              element={
                <PrivateRoute>
                  <PickupPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/pickup/detail/:picid"
              element={
                <PrivateRoute>
                  <PicDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/pickup/edit/:picid"
              element={
                <PrivateRoute>
                  <PicEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/pickup/allot/:picid"
              element={
                <PrivateRoute>
                  <PAllotOrder />
                </PrivateRoute>
              }
            />
            <Route
              path="/subuser/create"
              element={
                <PrivateRoute>
                  <SubUserRegister />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons"
              element={
                <PrivateRoute>
                  <Coupons />
                </PrivateRoute>
              }
            />
            <Route
              path="/garage"
              element={
                <PrivateRoute>
                  <Garage />
                </PrivateRoute>
              }
            />
            <Route
              path="/downloadinpdf"
              element={
                <PrivateRoute>
                  <Downloadinpdf />
                </PrivateRoute>
              }
            />
            <Route
              path="/notificationcomponent"
              element={
                <PrivateRoute>
                  <NotificationComponent />
                </PrivateRoute>
              }
            />
            <Route path="/loginform" element={<LoginForm />} />
            <Route
              path="/privatePage"
              element={
                <PrivateRoute>
                  <PrivatePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/postvehicle"
              element={
                <PrivateRoute>
                  <PostVehicle />
                </PrivateRoute>
              }
            />
            <Route
              path="/mapvehicles"
              element={
                <PrivateRoute>
                  <Mapvehicles />
                </PrivateRoute>
              }
            />
            <Route
              path="/Contactus"
              element={
                <PrivateRoute>
                  <Contactus />
                </PrivateRoute>
              }
            />
            <Route
              path="/imagesupload"
              element={
                <PrivateRoute>
                  <ImagesUpload />
                </PrivateRoute>
              }
            />
            <Route
              path="/products"
              element={
                <PrivateRoute>
                  <Products />
                </PrivateRoute>
              }
            />
            <Route
              path="/allproducts"
              element={
                <PrivateRoute>
                  <AllProducts/>
                </PrivateRoute>
              }
            />
          </Routes>
        </SideBar>
      </Router>
    </AuthProvider>
  );
}

export default App;
