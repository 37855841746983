import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../api/api";
const CusCreate = () => {
  const navigate = useNavigate();
  const [id] = useState("");
  const [name, namechange] = useState("");
  const [email, emailchange] = useState("");
  const [password, passwordchange] = useState("");
  const [phone_no, phone_nochange] = useState("");
  const [city, setCity] = useState("");
  const [validation, valchange] = useState(false);

  const handlesubmit = (e) => {
    e.preventDefault();
    const newCustomerdata = {
      name,
      email,
      password,
      
    };

    const apiRequest1 = fetch(`${apiUrl}/api/customer/register`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(newCustomerdata),
    });

    Promise.all([apiRequest1])
      .then((responses) => {
        // Handle the responses here
        const [response1] = responses;
        if (response1.ok) {
          alert("Saved successfully.");
          navigate("/customers");
        } else {
         
          console.log("Request failed.");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="offset-lg-12">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Create New Customer</h2>
              </div>
              <div className="card-body">
                <div className="row">
                 

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        required
                        value={name}
                        onMouseDown={(e) => valchange(true)}
                        onChange={(e) => namechange(e.target.value)}
                        className="form-control"
                      ></input>
                      {name.length === 0 && validation && (
                        <span className="text-danger">Enter the name</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={email}
                        onChange={(e) => emailchange(e.target.value)}
                        className="form-control"
                        type="email"
                      ></input>
                    </div>
                  </div>

                  

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        value={password}
                        onChange={(e) => passwordchange(e.target.value)}
                        className="form-control"
                        type="password"
                      ></input>
                    </div>
                  </div>

                
                  <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save
                      </button>

                      <button
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CusCreate;
