import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { MdDetails } from "react-icons/md";


const Order = () => {
  const [orders, setOrders] = useState([]);
  const [notification, setNotification] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const navigate = useNavigate();

  const apiUrl = "https://gmi-server-1.onrender.com";

  const LoadDetail2 = (item) => {
    navigate(`/order/detail/${item._id}`, { state: { item } });
  };

  const handleServiceProvider = (_id) => navigate(`/serviceprovider`);

  const Removefunction = async (_id) => {
    if (window.confirm("Do you want to remove?")) {
    try {
      await axios.delete(
        `https://gmi-server-1.onrender.com/api/newmyorder/${_id}`
      );
      setOrders(orders.filter((order) => order._id !== _id));
      setNotification("Order deleted successfully");
      setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
    } catch (error) {
      console.error("Failed to delete order", error);
      setNotification("Failed to delete order");
      setTimeout(() => setNotification(""), 3000); // Clear notification after 3 seconds
    }}
  };

 

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/newmyorder/getallnewmyorder`);
        const data = await response.data;
        const sortedOrders = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setOrders(sortedOrders);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      }
    };
  
    fetchOrders();
  }, []);

  

  const filteredOrders =
    selectedStatus === "all"
      ? orders
      : orders.filter((item) => item.status === selectedStatus);

  return (
    <div className="container">
      {notification && <div className="notification">{notification}</div>}
      <div className="card">
        <div className="card-title">
          <h2>All Orders List</h2>
        </div>
        <div className="card-body">
          <div className="divbtn">
            <button
              onClick={() => navigate("/order/create")}
              className="btn btn-success"
            >
              Add New Order
            </button>
          </div>
          <div className="status-filter">
            <label htmlFor="status-filter">Filter by Status:</label>
            <select
              id="status-filter"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="all">All</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
              <option value="failed">Failed</option>
              <option value="processing">Processing</option>
            </select>
          </div>
          <table style={{ border: "2px solid #e4ff03", padding: "5px" , background:"#fafae8"}}>
            <thead style={{ border: "2px solid #e4ff03", padding: "5px" , background:"#FEC324" , textTransform: "uppercase" , fontWeight:"bold"}}>
              <tr style={{ textAlign: "center" }}>
                <td>ID</td>
                <td>City</td>
                <td>Service Date</td>
                <td>Service Time</td>
                <td>Vehicle Type</td>
                <td>Service Name</td>
              
                <td>Created on</td>
                
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((item) => (
                <tr key={item._id}>
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                    #{item._id.slice(-5)}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                  {item.city}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                  {item.servicing_date}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                  {item.servicing_time}
                  </td>
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                  {item.vehicle_type}
                  </td>

                  <td style={{ border: "1px solid black", padding: "5px" }}>
                    {item.product_name[0].product_name}
                  </td>
                  
                  
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                  {item.createdAt.slice(0 , 10)}
                  </td>
                  
                  <td style={{ border: "1px solid black", padding: "5px" }}>
                    <button
                      style={{ margin: "1px" }}
                      onClick={() => Removefunction(item._id)}
                      className="btn btn-danger"
                    >
                      <RiDeleteBinLine />
                    </button>
                    <button
                      style={{ margin: "1px" }}
                      onClick={() => LoadDetail2(item)}
                      className="btn btn-primary"
                    >
                      <AiOutlineOrderedList />
                    </button>
                    {item.status !== "cancelled" &&
                      item.status !== "completed" && (
                        <button
                          style={{ margin: "1px" }}
                          onClick={() => handleServiceProvider(item._id)}
                          className="btn btn-warning"
                        >
                          <MdDetails />
                          Allot provider
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Order;
