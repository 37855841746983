import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

const SubmitOrderDetailForm = (props) => {
  const conponentPDF = useRef();

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  const [formData, setFormData] = useState({
    
    id: "",
    customer_name: "",
    cusNumber: "",
    final_total: "",
      location: "",
    reg_number: "",
    servicing_date: "",
    service_pro_ID: "",
  });

  useEffect(() => {
    setFormData({
       
      id:  props.data[0],
      customer_name:  props.data[1],
      cusNumber:  props.data[2],
      final_total:  props.data[3],
      location:  props.data[4],
      reg_number:  props.data[7],
      servicing_date:  props.data[6],
      service_pro_ID:  props.data[5],
    });
  }, [props.data]);

  const handleChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleReset = () => {
    setFormData({
      id: "",
      customer_name: "",
      cusNumber: "",
      final_total: "",
      location: "",
      reg_number: "",
      servicing_date: "",
      service_pro_ID: "",
    });
  };

  return (
    <>
      <Form>
        <div ref={conponentPDF} style={{ width: "100%" }}>
          <Form.Group controlId="title">
            <Form.Label>ID : </Form.Label>
           
            <Form.Control
              type="text"
              value={formData.id}
              placeholder="Select the Name"
              onChange={(event) => handleChange(event, "id")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>customer_name : </Form.Label>
            <Form.Control
              type="text"
              value={formData.customer_name}
              placeholder="Select the customer_name"
              onChange={(event) => handleChange(event, "customer_name")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label> cusNumber : </Form.Label>

            <Form.Control
              type="text"
              value={formData.cusNumber}
              placeholder="Select the cusNumber"
              onChange={(event) => handleChange(event, "cusNumber")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>final_total : </Form.Label>
            <Form.Control
              type="text"
              value={formData.final_total}
              placeholder="Enter the final_total"
              onChange={(event) => handleChange(event, "final_total")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Location : </Form.Label>
            <Form.Control
              type="text"
              value={formData.location}
              placeholder="Enter the location"
              onChange={(event) => handleChange(event, "location")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>reg_number : </Form.Label>
            <Form.Control
              type="text"
              value={formData.reg_number}
              placeholder="Enter the reg_number"
              onChange={(event) => handleChange(event, "reg_number")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>servicing_date : </Form.Label>
            <Form.Control
              type="text"
              value={formData.servicing_date}
              placeholder="Enter the servicing_date"
              onChange={(event) => handleChange(event, "servicing_date")}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>service_pro_ID : </Form.Label>
            <Form.Control
              type="text"
              value={formData.service_pro_ID}
              placeholder="Enter the service_pro_ID"
              onChange={(event) => handleChange(event, "service_pro_ID")}
            />
          </Form.Group>
        </div>

        

        <Button className="mx-2" onClick={handleReset} variant="warning">
          Reset Feilds
        </Button>
        <Button className="btn btn-success" onClick={generatePDF}>
          Download PDF
        </Button>
      </Form>
    </>
  );
};

export default SubmitOrderDetailForm;
