import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const PickupPage = () => {
  const [data, setData] = useState([]);
  // console.log("data:", data);
  const pickupData = data.filter((item) => item.position === "pickup");
  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/pickup/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/pickup/edit/${_id}`);
  const AllotOrder = (_id) => navigate(`/pickup/allot/${_id}`);

  const Removefunction = (_id) => {
    if (window.confirm("Do you want to remove ?")) {
      fetch(`https://gmi-server-1.onrender.com/api/subusers/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          alert("Removed successfully.");
          window.location.reload();
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userInfo")) || {};
    const token = data.token;
    fetch("https://gmi-server-1.onrender.com/api/subusers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      {/* <Link to="/registrationgarage">
        <Button style={{ marginLeft: 10, marginBottom: 6 }} size="lg">
          Create new User
        </Button>
      </Link> */}

      <div className="container">
        <div className="card">
          <div className="card-title">
            <h2>Pick-up Listing</h2>
          </div>
          <div className="card-body">
            <div className="divbtn">
              <button
                onClick={() => navigate("/subuser/create")}
                className="btn btn-success"
              >
                {"Add New (+)"}
              </button>
            </div>
            <table className="">
              <thead className="bg-dark text-white">
                {/* <tr> */}
                <td>title</td>
                <td>first_name</td>
                <td>last_name</td>
                <td>Phone</td>
                <td>Orders</td>
                <td>Action</td>
                {/* </tr> */}
              </thead>
              <tbody>
                {pickupData.map((item) => (
                  <tr key={item._id}>
                    <td claspan="">{item.title}</td>
                    <td claspan="">{item.first_name}</td>
                    <td claspan="">{item.last_name}</td>
                    <td claspan="">{item.phone}</td>
                    <td claspan="">{"Not allot yet"}</td>
                    <td claspan="">
                      <button
                        onClick={() => LoadEdit(item._id)}
                        className="btn btn-success"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => Removefunction(item._id)}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => AllotOrder(item._id)}
                        className="btn btn-warning"
                      >
                        Allot Order
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupPage;
