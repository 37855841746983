// src/Products.js
import React, { useState, useEffect } from "react";
import productService from "./productService";
import "./AllProducts.css";

function Products() {
  const [isVisiable, setIsVisiable] = useState(false);

  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState({
    product_name: "",
    category: "",
    status: "",
    description: [],
    total: 0,
    image: "defaultSample.png",
    price_tag: "",
    nagpur: [
      {
        bike_segment: {
          "90-149 CC": "",
          "150-200 CC": "",
          "Above-200 CC": "",
        },
        car_segment: {
          Hatchback: "",
          Sedan: "",
          SUV: "",
        },
        breakdown_segment: {
          "day-night": "",
          day: "",
          night: "",
        },
      },
    ],
    pune: [
      {
        bike_segment: {
          "90-149 CC": "",
          "150-200 CC": "",
          "Above-200 CC": "",
        },
        car_segment: {
          Hatchback: "",
          Sedan: "",
          SUV: "",
        },
        breakdown_segment: {
          "day-night": "",
          day: "",
          night: "",
        },
      },
    ],
  });
  const [currentProductId, setCurrentProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const data = await productService.getAllProducts();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleNestedInputChange = (e, section, segment, key) => {
    const value = e.target.value;
    setProductData((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item) => ({
        ...item,
        [segment]: { ...item[segment], [key]: value },
      })),
    }));
  };

  const handleDescriptionChange = (index, key, value) => {
    const updatedDescription = productData.description.map((desc, idx) =>
      idx === index ? { ...desc, [key]: value } : desc
    );
    setProductData({ ...productData, description: updatedDescription });
  };

  const addDescriptionField = () => {
    setProductData({
      ...productData,
      description: [
        ...productData.description,
        { main_point: "", sub_points: [] },
      ],
    });
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    try {
      await productService.createProduct(productData);
      fetchProducts();
      alert("Product created successfully!");
      resetForm();
    } catch (error) {
      console.error("Error creating product:", error);
      alert("Error creating product.");
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      await productService.updateProduct(currentProductId, productData);
      fetchProducts();
      alert("Product updated successfully!");
      setCurrentProductId(null);
      resetForm();
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating product.");
    }
  };

  const handleDeleteProduct = async (id) => {
    // Ask for confirmation
    const confirmation = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (confirmation) {
      try {
        // Proceed with deletion
        await productService.deleteProduct(id);
        fetchProducts();
        alert("Product deleted successfully!");
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Error deleting product.");
      }
    }
  };

  const handleEditProduct = (product) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentProductId(product._id);
    setProductData({
      product_name: product.product_name,
      category: product.category,
      status: product.status,
      description: product.description,
      total: product.total,
      image: product.image,
      price_tag: product.price_tag,
      nagpur: product.nagpur,
      pune: product.pune,
    });
  };

  const resetForm = () => {
    setProductData({
      product_name: "",
      category: "",
      status: "",
      description: [],
      total: 0,
      image: "defaultSample.png",
      price_tag: "",
      nagpur: [
        {
          bike_segment: {
            "90-149 CC": "",
            "150-200 CC": "",
            "Above-200 CC": "",
          },
          car_segment: {
            Hatchback: "",
            Sedan: "",
            SUV: "",
          },
          breakdown_segment: {
            "day-night": "",
            day: "",
            night: "",
          },
        },
      ],
      pune: [
        {
          bike_segment: {
            "90-149 CC": "",
            "150-200 CC": "",
            "Above-200 CC": "",
          },
          car_segment: {
            Hatchback: "",
            Sedan: "",
            SUV: "",
          },
          breakdown_segment: {
            "day-night": "",
            day: "",
            night: "",
          },
        },
      ],
    });
  };

  return (
    <div
      style={
        {
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // margin: "auto",
          // align: "center",
          // border: "1px solid red",
        }
      }
    >
      <h1>Product Management</h1>

      <button
        onClick={() => {
          setIsVisiable(!isVisiable);
        }}
        style={{
          marginBottom: "10px",
          padding: "2px 10px",
          backgroundColor: "blue",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {isVisiable ? "Hide Products" : "Show Products"}
      </button>
      

      <form
        onSubmit={currentProductId ? handleUpdateProduct : handleCreateProduct}
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f9f9f9",
        }}
      >
        <lable>Product Name</lable>
        <input
          type="text"
          name="product_name"
          value={productData.product_name}
          onChange={handleInputChange}
          placeholder="Product Name"
          required
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />
        <lable> Category</lable>
        <input
          type="text"
          name="category"
          value={productData.category}
          onChange={handleInputChange}
          placeholder="Bike/Car"
          required
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />
        <lable> Status </lable>
        <input
          type="text"
          name="status"
          value={productData.status}
          onChange={handleInputChange}
          placeholder="Active/Completed"
          required
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />
        {productData.description.map((desc, index) => (
          <div key={index}>
            <lable>Description Main Point</lable>
            <input
              type="text"
              value={desc.main_point}
              onChange={(e) =>
                handleDescriptionChange(index, "main_point", e.target.value)
              }
              placeholder="Main Point"
              style={{
                marginBottom: "10px",
                padding: "2px",
                width: "100%",
                boxSizing: "border-box",
              }}
            />

            <lable>Description Sub Point</lable>
            <input
              type="text"
              value={desc.sub_points.join(", ")}
              onChange={(e) =>
                handleDescriptionChange(
                  index,
                  "sub_points",
                  e.target.value.split(",")
                )
              }
              placeholder="Sub Points (comma separated)"
              style={{
                marginBottom: "10px",
                padding: "2px",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </div>
        ))}
        <div>
          <button
            type="button"
            onClick={addDescriptionField}
            style={{
              marginBottom: "10px",
              padding: "2px",
              backgroundColor: "#FCC701",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add Description
          </button>
        </div>

        <lable>Total Price</lable>
        <input
          type="number"
          name="total"
          value={productData.total}
          onChange={handleInputChange}
          placeholder="Total"
          required
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />

        <lable>Product Img/Url/Use default</lable>

        <input
          type="text"
          name="image"
          value={productData.image}
          onChange={handleInputChange}
          placeholder="Image URL"
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />
        <lable>Price Tag</lable>
        <input
          type="text"
          name="price_tag"
          value={productData.price_tag}
          onChange={handleInputChange}
          placeholder="₹ 111 - 999"
          style={{
            marginBottom: "10px",
            padding: "2px",
            width: "100%",
            boxSizing: "border-box",
          }}
        />
        {["nagpur", "pune"].map((city) => (
          <div key={city}>
            <h3>{city.charAt(0).toUpperCase() + city.slice(1)}</h3>
            {Object.keys(productData[city][0]).map((segment) => (
              <div key={segment}>
                <h4>
                  {segment.replace("_", " ").charAt(0).toUpperCase() +
                    segment.replace("_", " ").slice(1)}{" "}
                  set Amount
                </h4>

                {Object.keys(productData[city][0][segment]).map((key) => (
                  <input
                    key={key}
                    type="text"
                    name={key}
                    value={productData[city][0][segment][key]}
                    onChange={(e) =>
                      handleNestedInputChange(e, city, segment, key)
                    }
                    placeholder={key}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
        <button
          type="submit"
          style={{
            padding: "15px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
            boxSizing: "border-box",
            marginTop: "2%",
          }}
        >
          {currentProductId ? "Update" : "Create"} Product
        </button>
      </form>

      {isVisiable && (
        <div className="product-list">
          <h3>All Products:</h3>
          <div className="shopContainer">
            {products.map((product) => (
              <div
                key={product._id}
                style={{
                  textDecoration: "none",
                  //   marginBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "2%",
                  borderRadius: "15px",
                  backgroundColor: "white",

                  width: "80%",
                  // height:'80%',
                }}
              >
                <div style={{ textAlign: "center", padding: "5%" }}>
                  <img
                    src={require(`../../assests/${product.image}`)}
                    alt="product item"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "grey",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {product.category}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    height: "15%",
                    fontWeight: "bold",
                    color: "black",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {product.product_name}
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#6660B6",
                    textAlign: "center",
                    margin: 7,
                  }}
                >
                  {product.price_tag}
                </div>
                <div>
                  <div
                    style={{
                      // border: "1px solid red",
                      width: "280px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      onClick={() => handleEditProduct(product)}
                      style={{
                        textDecoration: "none",
                        backgroundColor: "#FCC701",
                        fontSize: 15,
                        color: "black",
                        fontWeight: 500,
                        width: "100px",
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product._id)}
                      style={{
                        textDecoration: "none",
                        backgroundColor: "#FCC701",
                        fontSize: 15,
                        color: "black",
                        fontWeight: 500,
                        width: "100px",
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
         
        </div>
      )}
    </div>
  );
}

export default Products;
