import React from "react";

class NotificationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: null,
      interval: null,
    };
  }

  handleButtonClick = () => {
    Notification.requestPermission()
      .then((perm) => {
        if (perm === "granted") {
          const notification = new Notification("Example notification", {
            body: "This is more text",
            data: { hello: "world" },
            // icon: "Logo Centered.png",
            tag: "Welcome Message",
          });

          notification.addEventListener("error", (e) => {
            alert("Error: " + e.message);
          });

          this.setState({ notification });
        }
      })
      .catch((error) => {
        console.error("Failed to request notification permission: ", error);
      });
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      const interval = setInterval(() => {
        const notification = new Notification("Come back please", {
          body: "you gone in x seconds",
          tag: "Come Back",
        });
        this.setState({ notification });
      }, 100);
      this.setState({ interval });
    } else {
      const { interval, notification } = this.state;
      if (interval) clearInterval(interval);
      if (notification) notification.close();
      this.setState({ interval: null, notification: null });
    }
  };

  render() {
    return (
      <div>
        <button onClick={this.handleButtonClick}>Request Notification</button>
      </div>
    );
  }
}

export default NotificationComponent;
