// src/components/Authentication/LoginForm.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const adminCredentials = {
    username: "admin",
    password: "admin@123",
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate credentials
    if (
      username === adminCredentials.username &&
      password === adminCredentials.password
    ) {
      const userInfo = {
        username,
        token: "This is admin token",
        isAdmin: true,
        lastActive: Date.now(), 
      };
      login(userInfo);
      navigate("/");
    } else {
      alert("Invalid username or password");
    }

    setUsername("");
    setPassword("");
  };

   // Function to be called when the component is mounted
  const handleMount = () => {
    // window.location.reload();
  
    localStorage.removeItem("userInfo"); 
    // console.log("LoginForm component mounted!");
    
  };

  useEffect(() => {
    handleMount(); 
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "20px", 
        boxSizing: "border-box",
        width: "100vw",
        textAlign: "center",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "300px",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <label style={{ display: "block", marginBottom: "10px" }}>
          Username:
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "10px",
            }}
          />
        </label>
        <label style={{ display: "block", marginBottom: "10px" }}>
          Password:
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "10px",
            }}
          />
        </label>
        <button
          type="submit"
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
