import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PicDetail = () => {
  const navigate = useNavigate();
  const { picid } = useParams();

  const [picdata, setpicdata] = useState([]);
  // console.log(picdata);
  useEffect(() => {
    fetch(`https://gmi-server-1.onrender.com/api/subusers/${picid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setpicdata(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [picid]);
  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <div className="container">
            <div className="card row" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Customer Details:</h2>
              </div>
              <div className="card-body"></div>

              {picdata && (
                <div>
                  <h2>
                    The Customer name is : <b>{picdata.first_name}</b> (
                    {picdata._id})
                  </h2>
                  <h3>Contact Details</h3>
                  <h5>Email is : {picdata.email}</h5>
                  {/* <h5>Phone is : {picdata.billing.phone}</h5> */}

                  <button
                    className="btn btn-danger"
                    onClick={() => navigate(-1)}
                  >
                    Go back
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PicDetail;
