import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../api/api"; 

const OrdPickup = () => {
  const { ordid } = useParams();
  

  useEffect(() => {
    fetch(`${apiUrl}/${ordid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setAddress1(resp.shipping.address_1);
        setAddress2(resp.shipping.address_2);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [ordid]);

  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");

   const navigate = useNavigate();

  const handlesubmit = (e) => {
    e.preventDefault();

    const orderdata = {
      shipping: { address_1, address_2 },
    };

    fetch(`${apiUrl}/${ordid}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(orderdata),
    })
      .then((res) => {
        alert("Saved successfully.");
        navigate("/Orders");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="offset-lg-3 col-lg-6">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div className="card-title">
                <h2>Pickup & Drop Location Edit</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Pick-Up Location:</label>
                      <input
                        required
                        value={address_1}
                        onChange={(e) => setAddress1(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Drop Location:</label>
                      <input
                        required
                        value={address_2}
                        onChange={(e) => setAddress2(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                   <div className="col-lg-12">
                    <div className="form-group">
                      <button className="btn btn-success" type="submit">
                        Save
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => navigate(-1)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default OrdPickup;
