import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./garDetail.css";

const GarDetail = () => {
  const navigate = useNavigate();
  const { garid } = useParams();

  const [gardata, setgardata] = useState([]);
  // console.log("gardata:", gardata);

  useEffect(() => {
    fetch(`https://gmi-server-1.onrender.com/api/subusers/${garid}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setgardata(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [garid]);

  return (
   

    <div>
      <h2>User Details</h2>
      <table className="user-detail-table">
        <tbody>
          
          <tr>
            <th>First Name</th>
            <td>{gardata.first_name}</td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>{gardata.last_name}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{gardata.email}</td>
          </tr>
          
          <tr>
            <th>Phone</th>
            <td>{gardata.phone}</td>
          </tr>
          <tr>
            <th>Position</th>
            <td>{gardata.position}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{gardata.status ? "Active" : "Inactive"}</td>
          </tr>
          <tr>
            <th>Area</th>
            <td>{gardata.area}</td>
          </tr>
          
        </tbody>
      </table>
      <button className="btn btn-danger" onClick={() => navigate(-1)}>
        Go back
      </button>
    </div>
  );
};

export default GarDetail;
