import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AllJobCarts = () => {
  const [data, setData] = useState([]);
  // console.log("data:", data);

  const navigate = useNavigate();
  const LoadDetail = (_id) => navigate(`/alljobcart/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/garage/edit/${_id}`);
  const AllotOrder = (_id) => navigate(`/garage/allot/${_id}`);

  const Removefunction = (_id) => {
    if (window.confirm("Do you want to remove ?")) {
      fetch(
        `https://gmi-server-1.onrender.com/api/jobcart/deletejobcart/${_id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => {
          alert("Removed successfully.");
          window.location.reload();
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    
    fetch("https://gmi-server-1.onrender.com/api/newjobcart", {
       
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleStatus = (item) => {
    // console.log("StatusTry:", item);
  };

  return (
    <div>
      <div className="container">
        <div className="card">
          <div className="card-title">
            <h2>All Job Carts Listing</h2>
          </div>
          <div className="card-body">
            <div className="divbtn">
              <button
                onClick={() => navigate("/subuser/create")}
                className="btn btn-success"
              >
                {"Add New (+)"}
              </button>
            </div>
            <table className="">
              <thead className="bg-dark text-white">
                {/* <tr> */}
                <td>_id</td>
                <td>name</td>
                <td>first_name</td>
                {/* </tr> */}
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item._id}>
                    <td claspan="">{item._id}</td>
                    <td claspan="">{item.name}</td>
                    <td claspan="">{item.first_name}</td>

                    <td claspan="">
                      <button
                        onClick={() => LoadDetail(item._id)}
                        className="btn btn-primary"
                      >
                        Details
                      </button>

                      <button
                        onClick={() => Removefunction(item._id)}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllJobCarts;
