import React, { useState, useEffect } from "react";
// import axios from "axios";

const Coupons = ({ order }) => {
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");

 

  return (
    <div>
      coupons page
      {/* <h3>{order.name}</h3> */}
      {/* <p>Price: {order.price}</p>
      <input
        type="text"
        placeholder="Enter user ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <button onClick={handleAssign}>Assign to user</button>
      {message && <p>{message}</p>} */}
    </div>
  );
};

export default Coupons;
