import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import JobCart from "./JobCart";
import { Button, Form } from "react-bootstrap";

const AllotedOrdersList = () => {
  const { garid } = useParams();
  const [data, setJData] = useState("");
  console.log("DATA::", data);

  const navigate = useNavigate();

  const JobCartPage = (item) => {
    setJData([item._id, item.service_name]);
  };

  const [products, setProducts] = useState([]);
  console.log("products:", products);

  const [loading, setLoading] = useState(false);

  const RemoveAllotedOrder = async (item) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `https://gmi-server-1.onrender.com/api/subusers/${garid}/delete/${item._id}`
      );
      console.log(res.data.message);
      setProducts(products.filter((p) => p._id !== item._id));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getSelectedOrders = async () => {
    try {
      const response = await axios.get(
        `https://bike-server2.onrender.com/api/subusers/${garid}`
      );
      setProducts(response.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  const uniqueIds = [...new Set(products.map((item) => item._id))];

  useEffect(() => {
    getSelectedOrders();
  }, [garid]);

  if (loading) {
    return <div>Loading All vehicles data...</div>; // Display loading message while data is being fetched
  }

  return (
    <div>
      <p>Service Provider's alloted order list:</p>
      <h4>Service Provider's id: ( {garid} )</h4>

      <table className="">
        <thead className="bg-dark text-white">
          <tr>
            <td className="border" colSpan="1">
              #_id
            </td>
            <td className="border" colSpan="1">
              service_name
            </td>
            <td className="border" colSpan="1">
              customer_name
            </td>
            <td className="border" colSpan="1">
              cusNumber
            </td>
            <td className="border" colSpan="1">
              location
            </td>
            <td className="border" colSpan="1">
              reg_number
            </td>
            <td className="border" colSpan="1">
              total
            </td>
            <td className="border" colSpan="1">
              servicing_date
            </td>
            <td className="border" colSpan="1">
              servicing_time
            </td>
          </tr>
        </thead>
        <tbody>
         
          {uniqueIds.map((id) => {
          
          const item = products.find((product) => product._id === id);
          return (
         

          <tr key={id}>
             <td className="border1" colSpan="">
               #_{item._id.toString().slice(-4)}
             </td>
             <td className="border1" colSpan="">
               {item.product_name[0].product_name}
             </td>

             <td className="border1" colSpan="1">
               {item.customer_name}
             </td>

             <td className="border1" colSpan="1">
               {item.cusNumber}
             </td>

             <td className="border1" colSpan="">
               {item.location}
             </td>

             <td className="border1" colSpan="">
               {item.reg_number}
             </td>

             <td className="border1" colSpan="">
               {item.total}
             </td>

             <td className="border1" colSpan="">
               {item.servicing_date}
             </td>
             <td className="border1" colSpan="">
               {item.servicing_time}
             </td>

             <td className="border">
              
             </td>
             <td className="border">
               <button
                disabled={loading}
                 onClick={() => RemoveAllotedOrder(item)}
                 className="btn btn-danger"
               >
                 Remove Alloted Order
               </button>
             </td>
           </tr>
         
             );
        })}
 
        </tbody>
      </table>
      {/* <JobCart data={data} /> */}

      <Button
        onClick={() => {
          navigate(-1);
        }}
        variant="danger"
      >
        Go Back
      </Button>
    </div>
  );
};

export default AllotedOrdersList;
