 

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { apiUrl } from "../../../api/api";
import { FaEdit } from "react-icons/fa";
import { MdDetails } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
 
const GAllotOrder = () => {
  const { garid } = useParams();
  const [orders, setOrders] = useState([]);
  // console.log("orders::", orders);
  const navigate = useNavigate();

   
   const LoadDetail2 = (item) => {
     navigate(`/order/detail/${item._id}`, { state: { item } });
   };

  const handleAllotOrderNew = (item) => {
    const productsData = item;

    const requestData = {
      products: productsData,
    };

    fetch(`${apiUrl}/api/subusers/addfields/${garid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(
          `(${item._id}) Order Allotted to ProviderID: ${garid}`,
          data
        );
        alert(`(${item._id}) Order Allotted to ProviderID: ${garid}`);
         navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error allocating order. Please try again.");
      });
  };

  useEffect(() => {
    fetch(`${apiUrl}/api/newmyorder/getallnewmyorder`)
      .then((res) => res.json())
      .then((resp) => setOrders(resp))
      .catch((err) => console.error(err.message));
  }, []);

  return (
    <div className="container">
      <div className="card">
        <div className="card-title">
          <h2>Orders Listing</h2>
        </div>
        <div className="card-body">
          <table className="">
            <thead className="bg-dark text-white">
              <tr style={{ textAlign: "center" }}>
                <td>ID</td>
                <td>Product Name</td>
                <td>Total:</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {orders.map((item) => (
                <tr key={item._id}>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    {/* {item._id} */}#{" "}
                    {item && item._id ? item._id.slice(-4) : ""}
                  </td>

                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    {item.product_name.length > 0 ? (
                      item.product_name.map((product) => (
                        <div key={product._id}>
                          <p>{product.product_name}</p>
                        </div>
                      ))
                    ) : (
                      <p>No products</p>
                    )}
                  </td>

                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    {item.product_name.length > 0 ? (
                      item.product_name.map((product) => (
                        <div key={product._id}>
                          <p> {product.total}</p>
                        </div>
                      ))
                    ) : (
                      <p>No Total</p>
                    )}
                  </td>
                  <td style={{ border: "1px solid black", padding: "2px" }}>
                    <button
                      style={{ margin: "1px" }}
                      onClick={() => LoadDetail2(item)}
                      className="btn btn-primary"
                    >
                      <AiOutlineOrderedList />
                    </button>
                    <button
                      style={{ margin: "1px" }}
                      className="btn btn-warning"
                      onClick={() => handleAllotOrderNew(item)}
                    >
                      <BsCheckCircle />
                      Allot Order
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="danger"
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GAllotOrder;
