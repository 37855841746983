import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UserOutlined,
  CarOutlined,
  AuditOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { Card, Space, Statistic, Typography, Button } from "antd";
import { useEffect, useState } from "react";
import { FcBusinessman } from "react-icons/fc";
import { FcDeployment } from "react-icons/fc";
import { FcServices } from "react-icons/fc";
import { FcSurvey } from "react-icons/fc";




import axios from "axios";
import "./dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { apiUrl } from "../../api/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [orders, setOrders] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);

  // console.log("totalCustomers::", totalCustomers);

  const [totalJobcart, setTotalJobcart] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalSubusers, setTotalSubusers] = useState(0);

  const [orderss, setOrderss] = useState([]);
  // console.log("OrderData:", orderss);

  const getCustomersData = () => {
    fetch(`${apiUrl}/api/customer/allcustomers`)
      .then((response) => response.json())
      .then((data) => {
        const customersCount = data.length;
        setTotalCustomers(customersCount);
      })
      .catch((error) => console.error(error));
  };

  const getmyOrdersData = () => {
   
    fetch(`${apiUrl}/api/newmyorder/getallnewmyorder`)
      .then((response) => response.json())
      .then((data) => {
        const ordersCount = data.length;
        setTotalOrders(ordersCount);
      })
      .catch((error) => console.error(error));
  };

  const getJobcartData = () => {
    fetch(`${apiUrl}/api/newjobcart`)
      .then((response) => response.json())
      .then((data) => {
        const jobcartCount = data.length;
        setTotalJobcart(jobcartCount);
      })
      .catch((error) => console.error(error));
  };
  const getProductsData = () => {
    
    fetch(`${apiUrl}/api/products/allproducts`)
    
      .then((response) => response.json())
      .then((data) => {
        const productsCount = data.length;
        setTotalProducts(productsCount);
      })
      .catch((error) => console.error(error));
  };
  const getServiceProviderData = () => {
    fetch(`${apiUrl}/api/subusers`)
     
      .then((response) => response.json())
      .then((data) => {
        const serviceproCount = data.length;
        setTotalSubusers(serviceproCount);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getmyOrdersData();
    getCustomersData();
    getJobcartData();
    getProductsData();
    getServiceProviderData();
  }, []);

  return (
    
    <Space size={40} direction="vertical" style={{ margin: '40px' }}>
      <Typography.Title level={4} style={{ textAlign: 'center' }}>Grease Monkey Dashboard</Typography.Title>
      <Typography.Title level={3}>Overview</Typography.Title>
      <Space direction="horizontal">
      
        <DashboardCard
          icon={
            <FcBusinessman 
            size={70}/>
          }
          title={<h3 style={{ fontSize: '18px', color: 'black'}}>Total Customers</h3>}
          value={totalCustomers}
        />
        <DashboardCard
          icon={
            <FcDeployment 
            size={70}/>
          }
          title={<h3 style={{ fontSize: '18px', color: 'black'}}>Total Orders</h3>}
          value={totalOrders}
        />
        <DashboardCard
          icon={
            <FcServices
            size={70}/>
          }
          title={<h3 style={{ fontSize: '18px', color: 'black'}}>Total Service Providers</h3>}
          value={totalSubusers}
        />
        <DashboardCard
          icon={
            <FcSurvey
            size={70}
            />
          }
          title={<h3 style={{ fontSize: '18px', color: 'black'}}>Total Jobcards</h3>}
          value={totalJobcart}
        />
        
       
      </Space>
      <Space>
       
      </Space>
    </Space>
  );
}

function DashboardCard({ title, value, icon }) {
  return (
    <Card style={{ backgroundColor:"rgb(255, 236, 161)" }}>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value} />
      </Space>
    </Card>
  );
}

function RecentOrders() {
  const [loadings, setLoadings] = useState([]);

  const LoadEdit = (id) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[id] = true;
      return newLoadings;
    });
  };

  const enterLoading = (id) => {
    // console.log("ok::", id);
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[id] = true;
      return newLoadings;
    });

  

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[id] = false;
        return newLoadings;
      });
    }, 3000);
  };

  const [dataSource, setDataSource] = useState([]);
  // console.log("data,,,,:", dataSource);
  const [loading, setLoading] = useState(false);
  const [totalOrd, setTotalOrd] = useState(0);
  // console.log("totalOrd", totalOrd);
  dataSource.map((item) => {
    totalOrd += +item.total;
  
  });

  

  useEffect(() => {
    setLoading(true);
    apiUrl().then((res) => {
      const data = res;
      setDataSource(data);
      setLoading(false);
    });
  }, []);

  setTotalOrd(totalOrd);

  

  return (
    <>
      <Typography.Text>Recent Orders</Typography.Text>

      {dataSource.map((item) => {
        setTotalOrd(totalOrd + +item.total);
        return (
          <tr key={item.id}>
            <td colSpan="2">{item.id}</td>
            <td colSpan="2">{item.status}</td>
            <td colSpan="2">{item.date_createds}</td>
            <td colSpan="2">{item.billing.phone}</td>
            <td colSpan="2">{item.total}</td>
            <td colSpan="2">
              <Button
                type="primary"
                loading={loadings[0]}
              >
                Click me!
              </Button>
            </td>
          </tr>
        );
      })}
    </>
  );
}

function DashboardChart() {
  const [reveneuData, setReveneuData] = useState({
    labels: [],
    datasets: [],
  });


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Order Revenue",
      },
    },
  };

  return (
    <Card style={{ width: 500, height: 250 }}>
      <Bar options={options} data={reveneuData} />
    </Card>
  );
}



function Products() {
  const [services, setServices] = useState([]);
  // console.log("services:", services);

  useEffect(() => {
    fetch("https://bike-server2.onrender.com/products")
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error("Failed to fetch services", error));
  }, []);

  return (
    <>
      <Typography.Text>Recent Orders</Typography.Text>

      {services.map((item) => (
        <p key={item.id}>
          <p colSpan="2">{item.id}</p>
          <ul dangerouslySetInnerHTML={{ __html: item.short_description }} />
        </p>
      ))}
    </>
  );
}

export default Dashboard;


