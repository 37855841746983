import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../api/api";
import { FaEdit } from "react-icons/fa";
import { MdDetails } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  // console.log("customers::", customers);
  const navigate = useNavigate();

  const LoadDetail = (_id) => navigate(`/customer/detail/${_id}`);
  const LoadEdit = (_id) => navigate(`/customer/edit/${_id}`);

  

  const RemoveFunction = (_id) => {
    if (window.confirm("Do you want to remove?")) {
      fetch(`${apiUrl}/api/customer/${_id}`, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok) {
            alert("Removed successfully.");
            window.location.reload();
          } else {
            throw new Error(
              `Failed to remove: ${res.status} ${res.statusText}`
            );
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    fetch(`${apiUrl}/api/customer/allcustomers`)
      .then((res) => res.json())
      .then((resp) => setCustomers(resp))
      .catch((err) => console.log(err.message));
  }, []);

  return (
   
    <div className="container">
      <div className="card">
        <div className="card-title">
          <h2>Customers</h2>
        </div>
        <div className="card-body">
          <div className="divbtn">
            <button
              onClick={() => navigate("/customer/create")}
              className="btn btn-success"
            >
              {"Add New Customer"}
            </button>
          </div>
          <table style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#fafae8"}}>
            <thead style={{ border: "2px solid #e4ff03", padding: "10px" , background:"#FEC324" , textTransform: "uppercase" , fontWeight:"bold"}}
                claspan="">
              <tr style={{ textAlign: "center" }}>
                <td>Sr No.</td>
                <td>Customer Name</td>
                <td>City</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {customers.map((item, index) => (
                <tr key={item._id}>
                  <td
                    style={{ border: "1px solid black", padding: "10px" }}
                    claspan=""
                  >
                    {/* {item._id} */}
                    {/* {item && item._id ? item._id.slice(-4) : ""} */}
                    {index + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px 200px 10px 50px" }}
                    claspan=""
                  >
                    <span style={{textTransform: "uppercase" , fontWeight:"BOLD"}}>{item.name}</span>
                  </td>
                  
                  <td
                    style={{ border: "1px solid black", padding: "10px 200px 10px 50px"}}
                    claspan=""
                  >
                    <span style={{textTransform: "uppercase"}}>{item.myOrders[0]?.city}</span>
                  </td>
                  <td
                    style={{ border: "1px solid black", padding: "10px 100px 10px 50px" }}
                    claspan=""
                  >
                    <button
                      style={{ margin: "1px" }}
                      onClick={() => LoadEdit(item._id)}
                      className="btn btn-success"
                    >
                      <FaEdit />
                    </button>

                    <button
                      style={{ margin: "1px" }}
                      onClick={() => RemoveFunction(item._id)}
                      className="btn btn-danger"
                    >
                      <RiDeleteBinLine />
                    </button>
                    <button
                      style={{ margin: "1px" }}
                      onClick={() => LoadDetail(item._id)}
                      className="btn btn-primary"
                    >
                      <AiOutlineOrderedList />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  );
};

export default Customer;
