// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userInfo")) || null
  );

  const login = (userInfo) => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    setUser(userInfo);
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    setUser(null);
  };

  useEffect(() => {
    const checkLogout = () => {
      const lastActive = user?.lastActive;
      const now = Date.now();
        const logoutTime = 30 * 60 * 1000; 
  
      if (lastActive && now - lastActive > logoutTime) {
        logout();
      }
    };

    const interval = setInterval(checkLogout, 1000); 

    return () => clearInterval(interval);
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
